<mat-toolbar
	style="background-color: #b70000"
	*ngIf="isOffline">
	<div class="flex flex-row items-center justify-center w-full">
		<mat-icon class="text-white">cloud_off</mat-icon>
		<div class="text-white font-bold">{{ "core.internet-lost" | translate }}</div>
	</div>
</mat-toolbar>
<mat-sidenav-container
	class="h-full"
	autosize>
	<mat-sidenav
		[mode]="isSmallScreen() ? 'over' : 'side'"
		[opened]="navOpened"
		*ngIf="authService.isAuth()"
		style="min-width: 300px">
		<mat-toolbar
			color="primary"
			class="flex flex-row justify-between">
			<div class="flex flex-col">
				<div>
					{{
						userService.getFirstName() === "__GROUP__"
							? userService.getLastName()
							: userService.getUserName()
					}}
				</div>
				<div style="margin-top: -12px; font-size: 0.7em; font-style: italic; font-weight: normal">
					{{ userService.getInstituteLabel() }}
				</div>
			</div>
			<img
				[src]="imgURL"
				alt="Logo"
				class="h-8 w-auto cursor-pointer"
				(click)="navigateToHome()" />
		</mat-toolbar>
		<mat-nav-list class="flex flex-col">
			<div mat-subheader>{{ "APP_FUNCTIONS_MAIN" | translate }}</div>
			<a
				mat-list-item
				[routerLink]="'protocols'"
				routerLinkActive="activeItem">
				<div
					class="flex flex-row items-center"
					routerLinkActive="activeItem">
					<mat-icon matPrefix>import_contacts</mat-icon>
					<label>{{ "APP_PROTOCOLS" | translate }}</label>
				</div>
			</a>
			<a
				*ngFor="let item of navigationSpecialtyItems"
				mat-list-item
				[routerLink]="'protocols/subitems/' + item.id"
				class="protocol-specialty">
				<div class="flex flex-row items-center">
					<mat-icon
						matPrefix
						*ngIf="item.icon.startsWith('material'); else base64Icon"
						>{{ getIcon(item) }}
					</mat-icon>
					<ng-template #base64Icon>
						<img
							alt="logo"
							src="{{ getIcon(item) }}"
							style="margin-right: 8px; width: 24px; height: 24px" />
					</ng-template>
					<label>{{ item.label }}</label>
				</div>
			</a>
			<a
				mat-list-item
				[routerLink]="'videos'"
				routerLinkActive="activeItem">
				<div
					class="flex flex-row items-center"
					routerLinkActive="activeItem">
					<mat-icon matPrefix>video_library</mat-icon>
					<label>{{ "videos.navigation-menu.title" | translate }}</label>
				</div>
			</a>
			<a
				mat-list-item
				[routerLink]="'pharmacy/drugs'"
				routerLinkActive="activeItem">
				<div
					class="flex flex-row items-center"
					routerLinkActive="activeItem">
					<mat-icon matPrefix>vaccines</mat-icon>
					<label>{{ "drugs.navigation-menu.title" | translate }}</label>
				</div>
			</a>
			<a
				mat-list-item
				[routerLink]="'my-protocols'"
				routerLinkActive="activeItem">
				<div
					class="flex flex-row items-center"
					routerLinkActive="activeItem">
					<mat-icon matPrefix>chrome_reader_mode</mat-icon>
					<label>{{ "APP_MY_PROTOCOLS" | translate }}</label>
				</div>
			</a>
			<a
				mat-list-item
				[routerLink]="'tools'"
				routerLinkActive="activeItem">
				<div
					class="flex flex-row items-center"
					routerLinkActive="activeItem">
					<mat-icon matPrefix>games</mat-icon>
					<label>{{ "tools.label" | translate }}</label>
				</div>
			</a>
			<a
				mat-list-item
				[routerLink]="'directory'"
				routerLinkActive="activeItem">
				<div
					class="flex flex-row items-center"
					routerLinkActive="activeItem">
					<mat-icon matPrefix>class</mat-icon>
					<label>{{ "APP_DIRECTORY_PHONE" | translate }}</label>
				</div>
			</a>
			<a
				mat-list-item
				[routerLink]="'misc/research'"
				routerLinkActive="activeItem">
				<div
					class="flex flex-row items-center"
					routerLinkActive="activeItem">
					<mat-icon matPrefix>science</mat-icon>
					<label>{{ "research.navigation-item" | translate }}</label>
				</div>
			</a>
			<mat-divider></mat-divider>
			<div
				mat-subheader
				*ngIf="manager.isAdmin() || manager.isAdminUsers() || manager.isQualityRole()">
				{{ "APP_ADMINISTRATION" | translate }}
			</div>
			<a
				mat-list-item
				[routerLink]="'admin/protocols'"
				*ngIf="manager.isAdmin()"
				routerLinkActive="activeItem">
				<div
					class="flex flex-row items-center"
					routerLinkActive="activeItem">
					<mat-icon matPrefix>auto_stories</mat-icon>
					<label>{{ "APP_PROTOCOLS" | translate }}</label>
				</div>
			</a>
			<a
				mat-list-item
				[routerLink]="'admin/videos'"
				*ngIf="manager.isAdmin()"
				routerLinkActive="activeItem">
				<div
					class="flex flex-row items-center"
					routerLinkActive="activeItem">
					<mat-icon matPrefix>video_library</mat-icon>
					<label>{{ "videos.navigation-menu.title" | translate }}</label>
				</div>
			</a>
			<a
				mat-list-item
				[routerLink]="'admin/formulas'"
				*ngIf="manager.isAdmin()"
				routerLinkActive="activeItem">
				<div
					class="flex flex-row items-center"
					routerLinkActive="activeItem">
					<mat-icon matPrefix>games</mat-icon>
					<label>{{ "tools.formulas.label" | translate }}</label>
				</div>
			</a>
			<a
				mat-list-item
				[routerLink]="'dashboard'"
				*ngIf="manager.isAdmin()"
				routerLinkActive="activeItem">
				<div
					class="flex flex-row items-center"
					routerLinkActive="activeItem">
					<mat-icon matPrefix>dashboard</mat-icon>
					<label>{{ "dashboard.title.label" | translate }}</label>
				</div>
			</a>
			<a
				mat-list-item
				[routerLink]="'pharmacy/booklet'"
				*ngIf="manager.isPharmacist() || manager.isAdmin()"
				routerLinkActive="activeItem">
				<div
					class="flex flex-row items-center"
					routerLinkActive="activeItem">
					<mat-icon matPrefix>medication</mat-icon>
					<label>{{ "drugs.pharmacy.label" | translate }}</label>
				</div>
			</a>
			<a
				mat-list-item
				[routerLink]="'quality'"
				*ngIf="manager.isQualityRole() || manager.isAdmin()"
				routerLinkActive="activeItem">
				<div
					class="flex flex-row items-center"
					routerLinkActive="activeItem">
					<mat-icon matPrefix>topic</mat-icon>
					<label>{{ "quality.title" | translate }}</label>
				</div>
			</a>
			<a
				mat-list-item
				[routerLink]="'users'"
				*ngIf="manager.isAdminUsers()"
				routerLinkActive="activeItem">
				<div
					class="flex flex-row items-center"
					routerLinkActive="activeItem">
					<mat-icon matPrefix>supervisor_account</mat-icon>
					<label>{{ "APP_USERS" | translate }}</label>
				</div>
			</a>
			<a
				mat-list-item
				[routerLink]="'institutes'"
				*ngIf="manager.isAdmin()"
				routerLinkActive="activeItem">
				<div
					class="flex flex-row items-center"
					routerLinkActive="activeItem">
					<mat-icon matPrefix>location_city</mat-icon>
					<label>{{ "APP_ESTABLISHMENTS" | translate }}</label>
				</div>
			</a>
			<a
				mat-list-item
				[routerLink]="'statistics'"
				routerLinkActive="activeItem">
				<div
					class="flex flex-row items-center"
					routerLinkActive="activeItem">
					<mat-icon matPrefix>pie_chart</mat-icon>
					<label>{{ "APP_STATISTICS" | translate }}</label>
				</div>
			</a>
			<a
				mat-list-item
				[routerLink]="'admin/settings'"
				*ngIf="manager.isAdmin()"
				routerLinkActive="activeItem">
				<div
					class="flex flex-row items-center"
					routerLinkActive="activeItem">
					<mat-icon matPrefix>settings</mat-icon>
					<label>{{ "core.settings" | translate }}</label>
				</div>
			</a>
			<mat-divider></mat-divider>
			<a
				mat-list-item
				href="https://help.medinthepocket.com"
				target="_blank"
				routerLinkActive="activeItem">
				<div
					class="flex flex-row items-center"
					routerLinkActive="activeItem">
					<mat-icon matPrefix>help</mat-icon>
					<label>{{ "help.label" | translate }}</label>
				</div>
			</a>
		</mat-nav-list>
	</mat-sidenav>
	<mat-sidenav-content>
		<mat-toolbar
			color="primary"
			*ngIf="authService.isAuth()">
			<div class="flex w-full flex-row items-center">
				<button
					mat-icon-button
					(click)="toggle()">
					<mat-icon>menu</mat-icon>
				</button>
				<div class="flex w-full flex-row justify-between">
					<div class="flex flex-col justify-center">
						<span>{{ title }}</span>
						<div
							style="margin-top: -12px; font-size: 0.7em; font-style: italic; font-weight: normal"
							*ngIf="title !== 'MedInThePocket'">
							{{ description }}
						</div>
					</div>
					<div class="flex flex-row items-center">
						<div>
							<button
								mat-icon-button
								(click)="themeService.setLightTheme()"
								*ngIf="themeService.isDarkTheme()">
								<mat-icon>light_mode</mat-icon>
							</button>
							<button
								mat-icon-button
								(click)="themeService.setDarkTheme()"
								*ngIf="!themeService.isDarkTheme()">
								<mat-icon>dark_mode</mat-icon>
							</button>
						</div>
						<app-notifications-view></app-notifications-view>
						<app-user-badge></app-user-badge>
					</div>
				</div>
			</div>
		</mat-toolbar>
		<ul
			class="lightrope"
			*appShowInDecember>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
		</ul>
		<router-outlet></router-outlet>
	</mat-sidenav-content>
</mat-sidenav-container>
