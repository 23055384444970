import { Component, Input } from "@angular/core";
import { NgClass } from "@angular/common";
import { KanbanRowComponent } from "../kanban-row/kanban-row.component";
import { Protocol } from "../../../../interfaces/protocols/protocol";

@Component({
	selector: "app-kanban-list",
	imports: [NgClass, KanbanRowComponent],
	templateUrl: "./kanban-list.component.html",
	styleUrl: "./kanban-list.component.scss",
})
export class KanbanListComponent {
	@Input() title: string = "";
	@Input() colorClass: string = "";
	@Input() items: Protocol[] = [];
}
