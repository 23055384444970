import { Component, Input } from "@angular/core";
import { KanbanListComponent } from "../../core/kanban/kanban-list/kanban-list.component";
import { Protocol } from "../../../interfaces/protocols/protocol";
import { NgxTolgeeModule } from "@tolgee/ngx";

@Component({
	selector: "app-protocols-kanban",
	imports: [KanbanListComponent, NgxTolgeeModule],
	templateUrl: "./protocols-kanban.component.html",
	styleUrl: "./protocols-kanban.component.scss",
})
export class ProtocolsKanbanComponent {
	@Input() offlineProtocols: Protocol[] = [];
	@Input() onlineProtocols: Protocol[] = [];
	@Input() archivedProtocols: Protocol[] = [];
	@Input() waitingApprovalProtocols: Protocol[] = [];
}
