import { Directive, TemplateRef, ViewContainerRef } from "@angular/core";

@Directive({
	selector: "[appShowInDecember]",
	standalone: true,
})
export class ShowInDecemberDirective {
	constructor(
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef
	) {
		this.checkDate();
	}

	private checkDate(): void {
		const currentMonth = new Date().getMonth();
		if (currentMonth === 11) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}
}
